<template>
  <default-layout>
    <div class=" p-4">
      <div class="p-4 mb-4 rounded-2xl shadow-2">
        <h3 class="font-bold text-xl">Thông tin người ứng</h3>
        <div class="flex justify-between mt-2">
          <p>Người ứng:</p>
          <p v-if="contact?.customer.name" class="font-bold">
            {{ Helper.getName(contact.customer.name) }}
          </p>
        </div>
        <div class="flex justify-between mt-2">
          <p>Công ty:</p>
          <p class="font-bold">{{ contact?.customer.company }}</p>
        </div>
        <div
          v-if="contact?.customer && contact?.customer.starts > 0"
          class=" flex justify-between mt-2"
        >
          <p>Đánh giá:</p>
          <div class=" flex flex-col items-end">
            <div class="text-warning">
              <icon-start-solid
                v-for="index in Math.floor(contact?.customer.starts)"
                :key="`${index}-mdi-star`"
                small
                >mdi-star
              </icon-start-solid>
              <icon-start-half
                v-for="index in Math.ceil(
                  contact?.customer.starts -
                    Math.floor(contact?.customer.starts),
                )"
                :key="`${index}-mdi-star-half-full`"
                small
                >mdi-star-half-full
              </icon-start-half>
              <icon-start-outlined
                v-for="index in Math.floor(5 - contact?.customer.starts)"
                :key="`${index}-mdi-star-outline`"
                small
                >mdi-star-outline
              </icon-start-outlined>
            </div>
            <span class="ml-1 text-sm">Đánh giá bởi Interloan</span>
          </div>
        </div>
      </div>
      <div class="p-4 mb-4 rounded-2xl shadow-2">
        <div class="info-loans-header flex justify-between">
          <h3 class="font-bold text-xl">Thông tin khoản ứng</h3>
          <div v-if="contact?.insurance" class="protect">
            <span
              class="protect-wrap text-xs  rounded text-primary border-primary border inline-flex items-center py-0.5 px-1"
            >
              <span>
                <icon-shield class="w-6 h-6" />
              </span>
              Bảo trợ {{ contact?.insurance }}%
            </span>
          </div>
        </div>
        <div class="text-white uppercase font-medium">
          <div
            v-if="contact?.cashback"
            class="inline-flex items-center  mr-2 inline-block text-xs rounded font-medium text-white uppercase relative rounded bg-tertiary mt-2"
          >
            <div
              class="-left-1 rounded-full border-solid border border-tertiary absolute bg-white text-tertiary w-8 h-8 text-center flex items-center  justify-center"
            >
              <span>
                <svg
                  class="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
            <div
              class="tag-discount-text px-2 h-6 inline-flex items-center ml-6 leading-none "
            >
              Hoàn tiền 100k
            </div>
          </div>
          <div
            v-if="contact?.noFee && contact?.noFee === 1"
            class="px-2 h-6 inline-flex items-center mr-2 text-xs rounded font-medium text-white bg-blue uppercase relative rounded bg-blue mt-2"
          >
            Miễn phí chuyển khoản
          </div>
        </div>
        <div class="grid grid-cols-2 mt-2">
          <div class="col-6">
            <p>Hợp đồng</p>
            <p class="font-bold">{{ contact?.contractId }}</p>
          </div>
          <div class="col-6">
            <p>Số tiền</p>
            <p v-if="contact?.amount" class="font-bold text-tertiary">
              {{ HelperCurrency.numberToMoney(contact.amount) }}đ
            </p>
          </div>
        </div>
        <div class="grid grid-cols-2 mt-2">
          <div class="col-6">
            <p>Kì hạn</p>
            <p class="font-bold">{{ contact?.term }} tháng</p>
          </div>
          <div class="col-6">
            <p>Lãi suất</p>
            <p class="font-bold interest text-primary">
              {{ contact?.interest }}%
            </p>
          </div>
        </div>
        <div v-if="time && !ofUser" class="count-down">
          <div class="count-down-wrap border border-solid border-tertiary">
            <b>Thời gian đầu tư còn lại</b>
            <div class="count-down-time">
              <span class="square-time text-white bg-tertiary">{{ hour }}</span>
              <span class="colon text-tertiary">:</span>
              <span class="square-time text-white bg-tertiary">{{
                minute
              }}</span>
              <span class="colon text-tertiary">:</span>
              <span class="square-time text-white bg-tertiary">{{
                second
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <base-button
        v-if="!ofUser"
        @click="handleClick"
        class="h-12 w-full border-16 bg-tertiary text-white"
      >
        Đầu tư ngay
      </base-button>
      <Dialog
        :dialog="dialog"
        :title="titleDialog"
        :content="contentDialog"
        @closeDialog="closeDialog"
      />
    </div>
  </default-layout>
</template>

<script lang="ts">
import * as HelperCurrency from '@/helpers/currency';
import * as Helper from '@/helpers';
import * as HelperTime from '@/helpers/helperTime';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { isValid } from 'date-fns';
import * as ApiContract from '@/apis/ApiContract';
import BaseButton from '@/components/Base/BaseButton.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import IconShield from '@/components/Icon/IconShield.vue';
import IconStartSolid from '@/components/Icon/IconStart.vue';
import IconStartHalf from '@/components/Icon/IconStartHalf.vue';
import IconStartOutlined from '@/components/Icon/IconStartOutlined.vue';

export default defineComponent({
  name: 'LenderShow',
  components: {
    IconStartOutlined,
    IconStartHalf,
    IconStartSolid,
    BaseButton,
    IconShield,
    DefaultLayout,
  },
  setup() {
    const route = useRoute();

    const theLoan = ref(null);
    const intervalProp = ref();
    const countdown = ref();
    const hour = ref();
    const minute = ref();
    const second = ref();
    const time = ref(false);

    // const contact = {
    //   contractId: 1790,
    //   amount: 5000000,
    //   term: 3,
    //   interest: 8,
    //   insurance: 20,
    //   view: 221,
    //   noFee: 1,
    //   expired: 1,
    //   cashback: 1,
    //   moneyDiscount: 100000,
    //   customer: {
    //     name: 'Trang',
    //     company: 'Thế Giới Di Động',
    //     starts: 1.5,
    //   },
    // };

    const fetchTheLoan = (id: number) => {
      ApiContract.show(id)
        .then(res => {
          theLoan.value = res.data;
        })
        .catch(er => {
          console.log(er);
        });
    };

    const convertNumberTo2Digit = (_number: number) => {
      const number = _number + '';
      if (number.length < 2) {
        return '0' + number;
      }
      return _number;
    };

    const countdownTimer = (expireTime: string) => {
      let duration: {
        hours: number;
        minutes: number;
        seconds: number;
        asSeconds: number;
      } | null;
      const interval = 1000; // 1 second

      // calculate difference between two times
      // based on time set in user's computer time / OS
      let currentTime = new Date().toString();
      // get duration between two times
      duration = HelperTime.diff2Date(expireTime, currentTime);

      // loop to countdown every 1 second
      intervalProp.value = setInterval(
        () => {
          currentTime = new Date().toString();
          // get updated duration
          duration = HelperTime.diff2Date(expireTime, currentTime);

          // if duration is >= 0
          if (duration && duration.asSeconds <= 0) {
            countdown.value = false;
            clearInterval(intervalProp.value);
            // hide the countdown element
          } else {
            countdown.value = true;
            // otherwise, show the updated countdown
            if (duration) {
              hour.value = convertNumberTo2Digit(duration.hours);
              minute.value = convertNumberTo2Digit(duration.minutes);
              second.value = convertNumberTo2Digit(duration.seconds);
            }
          }
        },
        interval,
        true,
      );
    };

    onMounted(() => {
      const id = +route.params.id;
      if (!isNaN(id)) {
        fetchTheLoan(id);
      }
      // Get time from localstorage
      const timeLocal = localStorage.getItem('time');
      if (timeLocal && isValid(new Date(timeLocal))) {
        // this.customInterval();
        countdownTimer(timeLocal);
        time.value = true;
      }
    });

    return {
      contact: theLoan,
      HelperCurrency,
      Helper,
      time,
      hour,
      minute,
      second,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-loans-header {
  @media (max-width: 374px) {
    display: block;
  }
}

.tag-discount-text {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: lightning;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.count-down {
  text-align: center;

  .count-down-wrap {
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;

    b {
      font-weight: 500;
    }

    .count-down-time {
      font-size: 16px;

      .square-time {
        padding: 2px 0;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
      }

      .colon {
        margin: 0 2px;
        font-weight: bold;
      }
    }
  }
}
</style>
