
import * as HelperCurrency from '@/helpers/currency';
import * as Helper from '@/helpers';
import * as HelperTime from '@/helpers/helperTime';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { isValid } from 'date-fns';
import * as ApiContract from '@/apis/ApiContract';
import BaseButton from '@/components/Base/BaseButton.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import IconShield from '@/components/Icon/IconShield.vue';
import IconStartSolid from '@/components/Icon/IconStart.vue';
import IconStartHalf from '@/components/Icon/IconStartHalf.vue';
import IconStartOutlined from '@/components/Icon/IconStartOutlined.vue';

export default defineComponent({
  name: 'LenderShow',
  components: {
    IconStartOutlined,
    IconStartHalf,
    IconStartSolid,
    BaseButton,
    IconShield,
    DefaultLayout,
  },
  setup() {
    const route = useRoute();

    const theLoan = ref(null);
    const intervalProp = ref();
    const countdown = ref();
    const hour = ref();
    const minute = ref();
    const second = ref();
    const time = ref(false);

    // const contact = {
    //   contractId: 1790,
    //   amount: 5000000,
    //   term: 3,
    //   interest: 8,
    //   insurance: 20,
    //   view: 221,
    //   noFee: 1,
    //   expired: 1,
    //   cashback: 1,
    //   moneyDiscount: 100000,
    //   customer: {
    //     name: 'Trang',
    //     company: 'Thế Giới Di Động',
    //     starts: 1.5,
    //   },
    // };

    const fetchTheLoan = (id: number) => {
      ApiContract.show(id)
        .then(res => {
          theLoan.value = res.data;
        })
        .catch(er => {
          console.log(er);
        });
    };

    const convertNumberTo2Digit = (_number: number) => {
      const number = _number + '';
      if (number.length < 2) {
        return '0' + number;
      }
      return _number;
    };

    const countdownTimer = (expireTime: string) => {
      let duration: {
        hours: number;
        minutes: number;
        seconds: number;
        asSeconds: number;
      } | null;
      const interval = 1000; // 1 second

      // calculate difference between two times
      // based on time set in user's computer time / OS
      let currentTime = new Date().toString();
      // get duration between two times
      duration = HelperTime.diff2Date(expireTime, currentTime);

      // loop to countdown every 1 second
      intervalProp.value = setInterval(
        () => {
          currentTime = new Date().toString();
          // get updated duration
          duration = HelperTime.diff2Date(expireTime, currentTime);

          // if duration is >= 0
          if (duration && duration.asSeconds <= 0) {
            countdown.value = false;
            clearInterval(intervalProp.value);
            // hide the countdown element
          } else {
            countdown.value = true;
            // otherwise, show the updated countdown
            if (duration) {
              hour.value = convertNumberTo2Digit(duration.hours);
              minute.value = convertNumberTo2Digit(duration.minutes);
              second.value = convertNumberTo2Digit(duration.seconds);
            }
          }
        },
        interval,
        true,
      );
    };

    onMounted(() => {
      const id = +route.params.id;
      if (!isNaN(id)) {
        fetchTheLoan(id);
      }
      // Get time from localstorage
      const timeLocal = localStorage.getItem('time');
      if (timeLocal && isValid(new Date(timeLocal))) {
        // this.customInterval();
        countdownTimer(timeLocal);
        time.value = true;
      }
    });

    return {
      contact: theLoan,
      HelperCurrency,
      Helper,
      time,
      hour,
      minute,
      second,
    };
  },
});
